<script>

import helpers from '../../helpers'

import {
  contentMethods,
} from "@/state/helpers";


export default {
	data() {
		return {
			history: []
    };
	},
  components:{},
  methods: {
    ...helpers,
    ...contentMethods,
    loadHistory(payload){
      const loader = this.$loading.show();

      this.getVariantHistory(payload).then((res)=>{
        this.history = res;
      }).catch(()=>{

      }).finally(()=>{
        loader.hide()
      })
    },
    onVersionSelected(version){
      this.$emit('selectVersion',version);
    }
  },

	mounted() {
    
	},
	props: {
		variant: Object
	},
	emits: ['selectVersion'],
  watch: {
    variant: {
      immediate:true,
      handler(newVal){
        if(newVal && newVal.personalizationId){
          this.loadHistory(newVal);
        }
      }
    }
  }
};
</script>

<template>
  <div class="table-responsive">
    <table class="table table-nowrap table-centered table-hover mb-0 align-middle">
      <tbody>
        <tr v-for="(h,index) in history" :key=h._id>
          <td style="width: 45px;">
            <div class="avatar-sm">
              <span class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24">
                <i class="bx bx-plus" v-if="index == history.length-1"></i>
                <i class="bx bx-pencil" v-else></i>
              </span>
            </div>
          </td>
          <td>
            <h5 class="font-size-14 mb-1">
              {{h.createdBy.name}}
            </h5>
            <small>{{ `${parseDateWithLocale(h.createdAt, {year: 'numeric', month: 'long', day: 'numeric'})} ${parseTimeWithLocale(h.createdAt, {hour: '2-digit', minute:'2-digit'})}` }}</small>
          </td>
          <td>
            <div class="text-center">
              <i class="bx bx-download h3 m-0 text-dark interact" v-on:click="onVersionSelected(h)"></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>