<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/no-mutating-props -->
<script>
import {
  
} from "@/state/helpers";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      
    };
  },
  components: { },
  mounted() {
  

  },
  methods: {
    getWinningVariant(data) {
      let idMax = null;
      let maxProbability = -1;

      // Iterar sobre las claves del objeto
      for (let id in data) {
        if (data.hasOwnProperty(id)) {
          let prob = data[id].probability_to_be_best;
          if (prob > maxProbability) {
            maxProbability = prob;
            idMax = id;
          }
        }
      }
      return idMax;
    },

    variantName(winner){
      const variant = this.experience.variants.filter(v=>v._id == winner).length > 0 ?this.experience.variants.filter(v=>v._id == winner)[0] : null;

      return variant ? variant.name : 'Winner'
    },
    variantWinningName(){
      if(this.metrics?.report?.result){
        const winningVariantId = this.getWinningVariant(this.metrics.report.result);
        const variant = this.experience.variants.filter(v=>v._id == winningVariantId).length > 0 ?this.experience.variants.filter(v=>v._id == winningVariantId)[0] : null;
        
        return variant ? variant.name : '-'
      }
      return ''
    },
		winnerSubtitle(winner){
			
      if(!winner || !winner.winner && !this.experience.settings || !this.metrics){
        return '';	
      }
      const variant = this.experience.variants.filter(v=>v._id == winner.winner).length > 0 ?this.experience.variants.filter(v=>v._id == winner.winner)[0] : null;
      if(!variant) return '';
      let text = '';
      const winnerData = this.metrics.data.filter(d=> d.variantId == variant._id)[0]; 
      
      switch (this.experience.settings.measure) {
        case 'purchase': 
          text= this.$t('personalizations.experience_variants_subtitle_winner_purchases');
          text = text.replace('##', winnerData?.conversions).replace('###', winnerData?.samples);
          break;
        case 'ctr':
          text= this.$t('personalizations.experience_variants_subtitle_winner_ctr');
          text= text.replace('##', winnerData?.ctr).replace('###', winnerData?.samples);
          break;
        case 'revenue':
          text= this.$t('personalizations.experience_variants_subtitle_winner_revenue')
          text= text.replace('##', winnerData?.revenue).replace('###', winnerData?.samples);
          break;
        case 'cr':
          text= this.$t('personalizations.experience_variants_subtitle_winner_conversion_rate')
          text= text.replace('##', winnerData?.conversion_rate).replace('###', winnerData?.samples);
          break;
      }

      return text;
    },
    winningSubtitle(){
      
      if(!this.experience.settings || !this.metrics){
        return '';	
      }
      const winningVariantId = this.getWinningVariant(this.metrics.report.result);

      if(!winningVariantId) return '';

      const variant = this.experience.variants.filter(v=>v._id == winningVariantId).length > 0 ?this.experience.variants.filter(v=>v._id == winningVariantId)[0] : null;
      if(!variant) return '';

      let text = '';
      const winnerData = this.metrics.data.filter(d=> d.variantId == variant._id)[0]; 
      
      switch (this.experience.settings.measure) {
        case 'purchase': 
          text= this.$t('personalizations.experience_variants_subtitle_winning_purchases');
          text = text.replace('##', winnerData?.conversions).replace('###', winnerData?.samples);
          break;
        case 'ctr':
          text= this.$t('personalizations.experience_variants_subtitle_winning_ctr');
          text= text.replace('##', winnerData?.ctr).replace('###', winnerData?.samples);
          break;
        case 'revenue':
          text= this.$t('personalizations.experience_variants_subtitle_winning_revenue')
          text= text.replace('##', winnerData?.revenue).replace('###', winnerData?.samples);
          break;
        case 'cr':
          text= this.$t('personalizations.experience_variants_subtitle_winning_conversion_rate')
          text= text.replace('##', winnerData?.conversion_rate).replace('###', winnerData?.samples);
          break;
      }

      return text;
    },
    winnerSubtitleDates(winner){
      let text = text= this.$t('personalizations.experience_variants_subtitle_winner_dates');
      text = text.replace('##', this.formatDate(this.experience?.firstImpressionDate)).replace('###', this.formatDate(winner.date));

      return text;
    },
    winningSubtitleDates(){
      let text = text= this.$t('personalizations.experience_variants_subtitle_winning_dates');
      text = text.replace('##', this.formatDate(this.experience?.firstImpressionDate))

      return text;
    },
    formatDate(date) {
      var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;
      
      return [day, month, year].join('/');
    }
  },
  props: {
    experience:Object,
    metrics: Object  
  },
	emits:[],
  watch: {
    metrics: {
			immediate:true,
			handler(newVal){
				if(newVal && newVal.report){
					
					if(newVal.report && newVal.report.result){
						this.experience.variants.forEach((v)=>{
							v.report = newVal.report.result[v._id];
						})
					}
				}
        if(newVal && newVal.data){
          this.experience.variants.forEach((v)=>{
							v.data = newVal.data.filter(d=> d.variantId == v._id).length > 0 ? newVal.data.filter(d=> d.variantId == v._id)[0] : null
						})
        }	
      }
		}
  }
}

</script>

<template>
  <div>
    <div class="row">
      <div class="col d-inline-flex align-items-baseline bg-primary bg-gradient p-2 mx-2" v-if="experience?.winner && experience?.winner?.winner != ''">
        <i class="bx bx-trophy font-size-24 me-2 text-white align-self-center"/>
        <div>
          <p class="mb-0 me-2 text-white"> <span class="fw-bold text-white">{{ variantName(experience.winner?.winner) }}</span> {{ winnerSubtitle(experience?.winner)}}</p>
          <p class="mb-0 me-2 text-white font-size-10"> {{ winnerSubtitleDates(experience.winner)}}</p>
        </div>
      </div>
      <div class="col d-inline-flex align-items-baseline bg-success bg-gradient p-2 mx-2" v-else>
        <i class="bx bx-trophy font-size-24 me-2 text-white align-self-center"/>
        <div>
          <p class="mb-0 me-2 text-white"> <span class="fw-bold text-white">{{ variantWinningName() }}</span> {{ winningSubtitle()}}</p>
          <p class="mb-0 me-2 text-white font-size-10"> {{ winningSubtitleDates()}}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
      <table class="table table align-end mt-3">
      <thead class="table-light">
        <tr>
          <th style="width:40px"></th>
          <th>{{$t('personalizations.name')}}</th>
          <th>{{$t('personalizations.impressions')}}</th>
          <th>{{$t('personalizations.convertions')}}</th>
          <th>{{$t('personalizations.convertion_rate')}}</th>
          <th>{{'Revenue'}}</th>
          <th>{{'AOV'}}</th>
          <th>{{$t('personalizations.experience_variants_winner_percentage_be_winner')}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(variant) in experience?.variants" :key="variant.name">
            <td style="width:40px">
              <i class="bx bxs-trophy font-size-22 me-1 text-primary" v-if="variant._id == experience?.winner?.winner"/>
            </td>
            <td class="align-middle" :class="{'fw-bold text-decoration-underline': variant._id == experience?.winner?.winner}">
              {{ variant.name }}
            </td>
            <td class="align-middle">
              {{ variant.data?.samples }}
            </td>
            <td class="align-middle">
              {{ variant.data?.conversions }}
            </td>
            <td class="align-middle">
              {{ `${((variant.report.conversion_rate)* 100).toFixed(1).replace(/[.,]0$/, "")}%`  }}
            </td>
            <td class="align-middle">
              {{ variant.data?.revenue ? `$${variant.data.revenue?.toFixed(2)}` : '' }}
            </td>
            <td class="align-middle">
              {{ variant.data?.aov ? `$${variant.data.aov?.toFixed(2)}` : ''  }}
            </td>
            <td class="align-middle"> {{ variant.report ? `${variant.report?.probability_to_be_best * 100}%` : '-' }}</td>
          </tr>
      </tbody>
    </table>
    </div>
  </div>
  </div>
</template>
<style>

</style>