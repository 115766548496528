<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import { required, requiredIf } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import Draggable from 'vuedraggable'
import Experience from '@/components/personalizations/experience'
import EmptyList from '@/components/widgets/empty_list'
import Multiselect from "vue-multiselect";
import abTestingReports from '@/components/personalizations/ab-testing-reports';

import {
	contentMethods,
  propertyMethods,
  projectMethods,
  goalMethods
} from "@/state/helpers";

/**
 * Audience component
 */
export default {
  page: {
    title: "Personalization",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, Draggable, Experience, EmptyList, Multiselect, abTestingReports },
  data() {
    return {
      title: '',
      items: [],
      personalization:{
        targets:[],
        goal:{
          _id: 'purchase',
          time: 24
        }
      },
      submitted: false,
			showExperienceModal : false,
			targets: [],
      experience:{},
      tabIndex: 0,
      index: 0,
      isUpdate :false,
      empty_config:{
        "title" : this.$t('personalizations.experiences_empty'),
        "subtitle" : this.$t('personalizations.experiences_empty_subtitle'),
        "buttonText" : this.$t('personalizations.new_experience'),
        icon: "fa-cubes"
      },
      pageLoaded: false,
      channel: null,
      pageTypes: [
        {id: "home", label: this.$t("personalizations.page_type_home") },
        {id: "product_detail", label: this.$t("personalizations.page_type_product_detail")},
        {id: "cart", label: this.$t("personalizations.page_type_cart")},
        {id: "checkout", label: 'Checkout'},
        {id: "category", label: this.$t("personalizations.page_type_category") },
        {id: "product_listing", label: this.$t("personalizations.page_type_product_listing") },
        {id: "search", label: this.$t("personalizations.page_type_search") },
        {id: "none", label: this.$t("personalizations.page_type_none") },
        {id: "any", label: this.$t("personalizations.page_type_any") },
      ],
      auxPageType: {
        id: "any", label: this.$t("personalizations.page_type_any")
      }, 
    triggers: [
        {id: "page_load", label: this.$t("personalizations.trigger_page_load")},
        {id: "wait", label: this.$t("personalizations.trigger_wait")},
        {id: "interaction", label: this.$t("personalizations.trigger_interaction") },
        {id: "exit_intent", label: this.$t("personalizations.trigger_exit_intent") },
        {id: "click_element", label: this.$t("personalizations.trigger_click_element") },
        {id: "mouseover_element", label: this.$t("personalizations.trigger_mouseover_element") }
      ],
      frequencies: [
        {id: "once_page", label: this.$t("personalizations.frequency_once_page")},
        {id: "once_sesion", label: this.$t("personalizations.frequency_once_sesion")},
        {id: "once_day", label: this.$t("personalizations.frequency_once_day") },
        {id: "once_week", label: this.$t("personalizations.frequency_once_week") },
        {id: "once_month", label: this.$t("personalizations.frequency_once_month") },
        {id: "once", label: this.$t("personalizations.frequency_once") }
      ],
      auxType : {
        id: "web", label: this.$t("personalizations.type_web")
      },
      interactions:[
      {id: "view", label: this.$t("personalizations.interaction_view")},
      {id: "like", label:this.$t("personalizations.interaction_like")},
      {id: "rate", label: this.$t("personalizations.interaction_rate")},
      {id: "cart", label: this.$t("personalizations.interaction_cart")},
      {id: "purchase", label:this.$t("personalizations.interaction_purchase")},
      ],
      positions:[
      {id: "replace", label: this.$t("personalizations.position_replace")},
      {id: "after", label: this.$t("personalizations.position_after")},
      {id: "before", label: this.$t("personalizations.position_before")},
      ],
      auxFrequency : {
        id: "once_page", label: this.$t("personalizations.frequency_once_page")
      },
      auxInteraction : {
        id: "view", label: this.$t("personalizations.interaction_view")
      }, 
      auxTrigger:{
        id: "page_load", label: this.$t("personalizations.trigger_page_load")
      },
      auxPosition:{
        id: "replace", label: "Reemplazar"
      },
      confirmDelete: false,
      deleteIndex: -1,
      showABTestingReportModal:false,
      goals: [
      { _id: 'none',name : this.$t('goals.none')},
        { _id: 'purchase',name : this.$t('goals.purchase')},
        { _id: 'cart',name : this.$t('goals.cart')},
        { _id: 'like',name : this.$t('goals.like')},
        { _id: 'view',name : this.$t('goals.view')},
      ],
      auxGoal: { _id: 'purchase',name : this.$t('goals.purchase')},
      goal_times : [
        {id: 'hours', label:'Hours'},
        {id: 'days', label:'Days'},
      ],
      auxGoalTime:{id: 'days', label:'Days'},
      channelTypes : [
        { id: 'any',label : this.$t('personalizations.channel_any')},
        { id: 'web',label : 'Web'},
        { id: 'app',label : 'App'},
        { id: 'server',label : 'Server'},
      ],
      auxChannelType: { id: 'any',label : this.$t('personalizations.channel_any')},
      auxDay: 1,
      auxHour: 0,
      timeError: false
    };
  },
  validations: {
    personalization: {
        name: { required},
        key: { required},
        selector: { 
          required: requiredIf((model)=> { return model.type == 'web' || model.type == 'recommendations' }),
        },
        triggerValue: {
            required: requiredIf((model)=> { return (model.trigger?.id == 'wait' || model.trigger?.id == 'click_element' || model.trigger?.id == 'mouseover_element')})
        }
        
    }
  },
  mounted(){
    const personalizationId = this.$route.query?.id;
    this.title = personalizationId ? '' : this.$t('personalizations.new_personalization')
    if(personalizationId){
      this.loadCustomGoals(true);
      let loader = this.$loading.show();
      this.getContent(personalizationId).then((personalization)=>{
        this.personalization = personalization.data;
        this.pageLoaded = true;
        this.title = this.personalization.name;
        this.channel = this.personalization.channel;
        this.auxPageType = this.personalization.pageType ? this.pageTypes.filter(pt=> pt.id == this.personalization.pageType)[0] : this.auxPageType;
        //this.personalization.type = this.personalization.type || this.channel == 'web' ? 'web' : 'API';
        this.auxType = this.personalization.type ? this.types.filter(pt=> pt.id == this.personalization.type)[0] : this.auxType;
        this.auxPosition = this.personalization.selectorPosition ? this.positions.filter(pt=> pt.id == this.personalization.selectorPosition)[0] : this.auxPosition;
        this.auxInteraction = this.personalization.trigger?.id == 'interaction' ? this.personalization.trigger?.value : this.auxInteraction;
        this.auxTrigger = this.personalization.trigger ? this.personalization.trigger : this.auxTrigger;
        this.auxFrequency = this.personalization.frequency ? this.frequencies.filter(f=> f.id == this.personalization.frequency)[0] : this.auxFrequency;
        this.personalization.triggerValue = this.personalization.trigger?.value;

        this.auxChannelType = this.personalization.channelType ? this.channelTypes.filter(pt=> pt.id == this.personalization.channelType)[0] : null;

        if(this.personalization.goal){
          this.auxGoal = this.goals.filter(g=>g._id == this.personalization.goal._id).length > 0 ? this.goals.filter(g=>g._id == this.personalization.goal._id)[0] : this.auxGoal;
          if(this.personalization.goal.time){
            this.auxDay = Math.floor(this.personalization.goal.time / 24)
            this.auxHour = this.personalization.goal.time % 24
          }
        }else{
          this.personalization.goal = {
            _id : 'purchase',
            time: 24
          }
        }
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('personalizations.get_personalization_error'),title:  this.$t('personalizations.title') });
      }).finally(()=>{
        loader.hide();
      })
    }else{
      this.loadCustomGoals(false);
      this.channel = this.$route.query?.channel?.includes('API')  ? 'API' : 'web';
      this.personalization.type = this.$route.query?.channel;
      this.personalization.channelType = this.$route.query?.channel?.includes('API') ? 'any' : 'web';
      this.pageLoaded = true;
      this.auxType = this.personalization.type ? this.types.filter(pt=> pt.id == this.personalization.type)[0] : this.auxType;
      this.onTypeChanged();
    }

  },
  computed:{
    types(){
      return [
        {id: "web", label: this.$t("personalizations.type_web")},
        {id: "recommendations", label: this.$t("personalizations.type_reco")},
        {id: "custom_code", label: this.$t("personalizations.type_custom_code") },
        {id: "pop_up", label: this.$t("personalizations.type_pop_up") },
        {id: "notifications", label: this.$t("personalizations.type_notifications") },
        {id: "feedback", label: 'Feedback' }
      ]
    } ,
  },
  methods:{
    ...contentMethods,
    ...propertyMethods,
    ...projectMethods,
    ...goalMethods,

    onRemoveExperienceConfirmClicked(){
      this.personalization.targets.splice(this.deleteIndex,1);
      let loader = this.$loading.show();
        this.updateContent(this.personalization).then(()=>{
          this.$notify({ type: 'success', text: this.$t('personalizations.update_personalization_success'),title:  this.$t('personalizations.title') });
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('personalizations.update_personalization_error'),title:  this.$t('personalizations.title') });
        }).finally(()=>{  
          loader.hide();
        })
    },
    onRemoveExperienceClicked(index){
      if(this.personalization._id){
        this.deleteIndex = index;
        this.confirmDelete = true;
      }else {
        this.personalization.targets.splice(index,1);
      }
    },
    onCloneExperienceClicked(experience){
      let exp = Object.assign({}, experience);
      exp.name = `Experience ${this.personalization.targets.length + 1}`;
      delete exp._id; 

      exp.variants.forEach(v => {
        delete v._id
      });

      const payload = {
        personalizationId: this.personalization._id,
        experience: exp,
        status: 1
      }
      let loader = this.$loading.show();
      this.createExperience(payload).then((exp)=>{
        if(exp.data){
          this.personalization = exp.data;
        }
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('personalizations.create_personalization_experience_error'),title:  this.$t('personalizations.title') });
      }).finally(()=>{
        loader.hide();
      })

      //this.personalization.targets.push(exp);

    },
    onEditExperienceClicked(experience){
      this.tabIndex = 0;
      this.isUpdate = true;
      this.experience = experience;
      this.showExperienceModal = true;
    },
		onNewExperienceClicked(){
      this.isUpdate = false;
      this.tabIndex = 0;
      this.experience = {
        rules: {
          type: "or",
          children: []
        },
        variants:[],
        status:1
      };
      this.experience.name = `Experience ${this.personalization.targets.length + 1}`
			this.showExperienceModal = true;
		},
    onCancelClicked(){
        this.$router.push({
            path: "/personalizations",
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
    },
    async onConfirmClicked(){
      this.submitted = true;
        
      this.$v.$touch();

      if (this.$v.$invalid || this.timeError) {
				return;
			}
      
      if(this.personalization.type == 'pop_up' || this.personalization.type == 'notifications' || this.personalization.type == 'custom_code'){
        this.personalization.frequency = this.auxFrequency.id;
        this.personalization.trigger.value = this.personalization.triggerValue;

        if(this.personalization.trigger?.id == 'interaction'){
          this.personalization.trigger.value = this.auxInteraction;
        }
       }

       this.personalization.pageType = this.auxPageType;

      if(this.personalization._id){
        if(!this.personalization.goal.time)
          this.personalization.goal.time = 24;
        let loader = this.$loading.show();
        this.updateContent(this.personalization).then(()=>{
          this.$notify({ type: 'success', text: this.$t('personalizations.update_personalization_success'),title:  this.$t('personalizations.title') });
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('personalizations.update_personalization_error'),title:  this.$t('personalizations.title') });
        }).finally(()=>{
          loader.hide();
        })
      }else{
        let loader = this.$loading.show();
        this.personalization.project = localStorage.getItem("current_project");
        this.personalization.status = 0;
        this.personalization.channel = this.channel;
        
        if(!this.personalization.goal.time)
          this.personalization.goal.time = 24;

        this.createContent(this.personalization).then(()=>{
          this.$notify({ type: 'success', text: this.$t('personalizations.create_personalization_success'),title:  this.$t('personalizations.title') });
          this.$router.push({
              path: "/personalizations",
              }
          ).catch(() => {});
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('personalizations.create_personalization_error'),title:  this.$t('personalizations.title') });
      }).finally(()=>{
        loader.hide();
      })
      }
    },
    onNextOrConfirmExperienceClicked(){
      if(this.tabIndex == 0){
        this.tabIndex = 1;
      }else{
        this.showExperienceModal = false;
        this.experience.name = this.experience.name || `Experience${this.personalization.targets.length + 1}`
        
        if(this.personalization._id){

          if(this.experience._id){
            const payload = {
              personalizationId: this.personalization._id,
              experience: this.experience
            } 
            let loader = this.$loading.show();
            this.updateExperience(payload).then((exp)=>{
              if(exp.data){
                this.personalization = exp.data;
                this.$notify({ type: 'success', text: this.$t('personalizations.update_personalization_experience_success'),title:  this.$t('personalizations.title') });
              }
            }).catch(()=>{
              this.$notify({ type: 'error', text: this.$t('personalizations.update_personalization_experience_error'),title:  this.$t('personalizations.title') });
            }).finally(()=>{
              loader.hide();
            })
          }else{
            const payload = {
              personalizationId: this.personalization._id,
              experience: this.experience
            }
            let loader = this.$loading.show();
            this.createExperience(payload).then((exp)=>{
              if(exp.data){
                this.personalization = exp.data;
                this.$notify({ type: 'success', text: this.$t('personalizations.create_personalization_experience_success'),title:  this.$t('personalizations.title') });
              }
            }).catch(()=>{
              this.$notify({ type: 'error', text: this.$t('personalizations.create_personalization_experience_error'),title:  this.$t('personalizations.title') });
            }).finally(()=>{
              loader.hide();
            })
          }
          
        }
        if(!this.isUpdate){
          this.personalization.targets.push(this.experience);
        }
      }
    },
    onTabIndexChanged(index){
      this.tabIndex = index;
    },
    onPageTypeChanged(){
      this.personalization.pageType = this.auxPageType;
    },
    onTypeChanged(){
      this.personalization.type = this.auxType.id;
      if(this.personalization.type == 'pop_up' || this.personalization.type == 'notifications' || this.personalization.type == 'custom_code' ){
        this.auxTrigger = {id: "page_load", label: "Al cargar la página"}
        this.personalization.trigger = this.auxTrigger;
        this.personalization.trigger.value = null;
      }else{
        this.personalization.trigger = null;
        this.personalization.frequency = null;
      }
    },
    onChannelTypeChanged(){
      if(this.channel == 'API'){
        this.personalization.channelType = this.auxChannelType.id;
      }
    },
    onTriggerChanged(){
        this.personalization.trigger =  this.auxTrigger;
        this.personalization.trigger.value = null; 
        this.personalization.triggerValue = null;
    },
    onPositionChanged(){
      this.personalization.selectorPosition = this.auxPosition.id;
    },
    onChangeStatusClicked(index){
        const status = this.personalization.targets[index].status == 1 ? 0 : 1;

        const experience = this.personalization.targets[index];
        experience.status = status;
        
        this.$set(this.personalization.targets, index, experience);

        const payload = {
              personalizationId: this.personalization._id,
              experience: experience
            } 
            let loader = this.$loading.show();
            this.updateExperience(payload).then((exp)=>{
              if(exp.data){
                this.personalization = exp.data;
                this.$notify({ type: 'success', text: this.$t('personalizations.update_personalization_experience_success'),title:  this.$t('personalizations.title') });
              }
            }).catch(()=>{
              this.$notify({ type: 'error', text: this.$t('personalizations.update_personalization_experience_error'),title:  this.$t('personalizations.title') });
            }).finally(()=>{
              loader.hide();
            })
      },
    onABTestingReportClicked(experience){
      this.experience = experience;
      this.showABTestingReportModal = true;
    },
    onGoalSelected(){
      if(!this.personalization.goal)
        this.personalization.goal = {}

      this.personalization.goal._id = this.auxGoal._id; 
    },
    loadCustomGoals(isUpdate){
      const params={
        q: `project=${localStorage.getItem('current_project')}&limit=50`,
      }
      this.getGoals(params).then((res)=>{
        this.goals = this.goals.concat(res.data);
        if(isUpdate){
          this.auxGoal = this.goals.filter(g=>g._id == this.personalization.goal._id).length > 0 ? this.goals.filter(g=>g._id == this.personalization.goal._id)[0] : this.auxGoal;
        }
      });
    },
    onTimeChanged(){
      if(!this.auxDay)
        this.auxDay = 1;

      if(!this.auxHour)
        this.auxHour = 0;

      this.personalization.goal.time = this.auxDay * 24 + this.auxHour;
      this.timeError = this.personalization.goal.time > (90 * 24)
    }
  },
  watch: {
    tabIndex:{
      immediate: true,
      handler(newVal){
        if(newVal){
        this.index = newVal;
        
        }
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" >
            <div class="row" >
                <div class="col-sm-4" :class="channel == 'web' || channel=='API' ? 'col-sm-4' : 'col-sm-6' ">
                    <div class="mb-3">
                        <label for="promotionName">{{$t('personalizations.name')}}*</label>
                        <input
                            id="promotionName"
                            v-model="personalization.name"
                            name="name"
                            type="text"
                            class="form-control"
                            :placeholder="$t('personalizations.name_placeholder')"
                            :class="{ 'is-invalid': submitted && $v.personalization.name.$error}"
                        />
                        <div v-if="submitted && !$v.personalization.name.required" class="invalid-feedback">
                          {{ $t('personalizations.name_required') }}
                        </div>
                    </div>
                </div>
                <div class="col-sm-4" :class="channel == 'web' || channel=='API' ? 'col-sm-4' : 'col-sm-6' ">
                    <div class="mb-3">
                        <label for="promotionName">{{ $t('personalizations.key') }}*</label>
                        <input
                            id="promotionName"
                            v-model="personalization.key"
                            type="text"
                            class="form-control"
                            :placeholder="$t('personalizations.key_placeholder')"
                            :class="{ 'is-invalid': submitted && $v.personalization.key.$error}"
                        />
                        <div v-if="submitted && !$v.personalization.key.required" class="invalid-feedback">
                          {{ $t('personalizations.key_required') }}
                        </div>
                    </div>
                </div>
                <div class="col-sm-4" v-if="channel == 'API'">
                  <div class="mb-3">
                        <label>{{ $t('personalizations.channel') }}*</label>
                        <multiselect
                          :options="channelTypes"
                          v-model="auxChannelType"
                          track-by="id" 
                          label="label"
                          selectLabel=""
                          deselectLabel=""
                          :allowEmpty="false"
                          class="form control"
                          @input="onChannelTypeChanged"
                        />
                  </div>
                </div>
                <div class="col-sm-4" v-if="channel == 'web'">
                  <div class="mb-3">
                        <label for="promotionName">{{ $t('personalizations.type') }}*</label>
                        <multiselect
                          :disabled="personalization?._id != null"
                          :options="types" 
                          v-model="auxType"
                          track-by="id" 
                          label="label"
                          selectLabel=""
                          deselectLabel=""
                          :allowEmpty="false"
                          class="form control"
                          @input="onTypeChanged"
                        />
                  </div>
                </div>
                <div class="col-sm-4" v-if="channel=='web' && (personalization.type == 'web' || personalization.type == 'recommendations')">
                    <div class="mb-3">
                        <label for="promotionName">{{$t("personalizations.position")}}*</label>
                        <multiselect
                          :options="positions" 
                          v-model="auxPosition"
                          track-by="id" 
                          label="label"
                          selectLabel=""
                          deselectLabel=""
                          :allowEmpty="false"
                          class="form control"
                          @input="onPositionChanged"
                        />
                    </div>
                </div>
                <div class="col-sm-4" v-if="channel=='web' && (personalization.type == 'web' || personalization.type == 'recommendations')">
                    <div class="mb-3">
                        <label for="promotionName">{{ $t('personalizations.selector') }}*</label>
                        <input
                            v-model="personalization.selector"
                            type="text"
                            class="form-control"
                            :placeholder="$t('personalizations.selector_placeholder')"
                            :class="{ 'is-invalid': submitted && $v.personalization.selector.$error}"
                        />
                        <div v-if="submitted && !$v.personalization.selector.required" class="invalid-feedback">
                          {{ $t('personalizations.selector_required') }}
                        </div>
                    </div>
                </div>
                <div class="col-sm-4" v-if="channel=='web' && (personalization.type == 'web' || personalization.type == 'recommendations')">
                    <div class="mb-3">
                        <label for="promotionName">{{ $t('personalizations.mobileSelector') }}</label>
                        <input
                            v-model="personalization.mobileSelector"
                            type="text"
                            class="form-control"
                            :placeholder="$t('personalizations.selector_placeholder')"
                        />
                    </div>
                </div>
                <div class="col-sm-4" v-if="channel=='web'">
                    <div class="mb-3">
                        <label for="promotionName">{{ $t('personalizations.page_type') }}*</label>
                        <multiselect
                          :disabled="personalization?._id != null"
                          :options="pageTypes" 
                          v-model="auxPageType"
                          track-by="id" 
                          label="label"
                          selectLabel=""
                          deselectLabel=""
                          :allowEmpty="false"
                          class="form control"
                          :placeholder="$t('personalizations.page_placeholder')"
                          @input="onPageTypeChanged"
                        />
                    </div>
                </div>
                <div  class="col-sm-4" v-if="channel=='web' && (personalization.type == 'custom_code' || personalization.type == 'pop_up' || personalization.type == 'notifications')">
                  <div class="mb-3">
                        <label for="promotionName">{{$t('personalizations.trigger')}}*</label>
                        <multiselect
                          :options="triggers" 
                          v-model="auxTrigger"
                          track-by="id" 
                          label="label"
                          selectLabel=""
                          deselectLabel=""
                          :allowEmpty="false"
                          class="form control"
                          @input="onTriggerChanged"
                        />
                  </div>
                </div>
                <div class="col-sm-4" v-if="channel=='web' && personalization.trigger?.id == 'wait'">
                    <div class="mb-3">
                        <label for="promotionName">{{$t('personalizations.trigger_wait_time')}}*</label>
                        <input
                            v-model="personalization.triggerValue"
                            type="number"
                            class="form-control"
                            :placeholder="$t('personalizations.trigger_wait_time_placeholder')"
                            :class="{ 'is-invalid': submitted && $v.personalization.triggerValue.$error}"
                        />
                        <div v-if="submitted && !$v.personalization.triggerValue.required" class="invalid-feedback">
                          {{$t('personalizations.trigger_wait_time_required')}}
                        </div>
                    </div>
                </div>
                <div class="col-sm-4" v-if="channel=='web' && personalization.trigger?.id == 'click_element' ||  personalization.trigger?.id == 'mouseover_element'">
                    <div class="mb-3">
                        <label for="promotionName">{{$t("personalizations.trigger_element")}}*</label>
                        <input
                            v-model="personalization.triggerValue"
                            class="form-control"
                            :placeholder="$t('personalizations.trigger_element_placeholder')"
                            :class="{ 'is-invalid': submitted && $v.personalization.triggerValue.$error}"
                        />
                        <div v-if="submitted && !$v.personalization.triggerValue.required" class="invalid-feedback">
                          {{ $t("personalizations.trigger_element_required") }}
                        </div>
                    </div>
                </div>
                <div class="col-sm-4" v-if="channel=='web' && personalization.trigger?.id == 'interaction'">
                    <div class="mb-3">
                        <label for="promotionName">{{$t('personalizations.trigger_interaction_select')}}*</label>
                        <multiselect
                          :options="interactions" 
                          v-model="auxInteraction"
                          track-by="id" 
                          label="label"
                          selectLabel=""
                          deselectLabel=""
                          :allowEmpty="false"
                          class="form control"
                        />
                    </div>
                </div>
                <div class="col-sm-4" v-if="channel=='web' && (personalization.type == 'custom_code' || personalization.type == 'pop_up' || personalization.type == 'notifications')">
                  <div class="mb-3">
                        <label for="promotionName">{{$t('personalizations.frequency')}}*</label>
                        <multiselect
                          :options="frequencies" 
                          v-model="auxFrequency"
                          track-by="id" 
                          label="label"
                          selectLabel=""
                          deselectLabel=""
                          :allowEmpty="false"
                          class="form control"
                        />
                  </div>
                </div>
                <div class="col-sm-4" >
                  <div class="mb-0">
                      <label for="promotionName">{{$t('goals.goal')}}*</label>
                      <div class="">
                        <multiselect
                          :options="goals" 
                          v-model="auxGoal"
                          track-by="_id" 
                          label="name"
                          selectLabel=""
                          deselectLabel=""
                          :allowEmpty="false"
                          class="me-2"
                          @input="onGoalSelected"
                          :show-labels="false"
                        />
                      </div>
                      <div class="d-inline-flex align-items-baseline mt-1" v-if="auxGoal?._id != 'none'">
                        {{$t('goals.goal_next')}} 
                        <b-form-input class="form-control form-control-sm ms-2 me-2" type="number" v-model="auxDay" style="width: 60px;" @input="onTimeChanged"></b-form-input>
                        {{$t('goals.goal_days')}}
                        <b-form-input class="form-control form-control-sm ms-2 me-2" type="number" v-model="auxHour" style="width: 60px;" @input="onTimeChanged"></b-form-input>
                        {{$t('goals.goal_hours')}} 
                      </div>
                      <div v-if="submitted && timeError" class="invalid-feedback" style="display: block;">
                        {{ $t('goals.goal_time_error') }}
                      </div>
                  </div>
                </div>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
				<div class="card">
					<div class="card-body">
						<div class="row" v-if="personalization.targets.length > 0">
							<label for="promotionName" class="mb-3 col card-title">{{ $t('personalizations.experiences') }}</label>
							<div class="text-end col">
								<button type="button" v-on:click="onNewExperienceClicked" class="btn btn-sm btn-primary mt-0 mb-3" >+ {{ $t('personalizations.new_experience') }}</button>
							</div>
						</div>
						
            <EmptyList :config="empty_config" v-if="pageLoaded && personalization.targets.length == 0" @onButtonClicked="onNewExperienceClicked"/>
						<Draggable v-model="personalization.targets" group="people" @start="drag=true" @end="drag=false" :forceFallback="true">
							<div class="card shadow-sm" v-for="(element,index) in personalization.targets" :key="element.id" style="background-color: rgba(85,110,230,.1); margin-bottom: 15px; ">
								<div class="card-body row ">
									<div class="col-sm-8" v-on:click="onEditExperienceClicked(element)" style="cursor: pointer;">
                    <div class="d-inline-flex align-items-baseline">
                      <i class="mdi-drag-vertical mdi align-self-center font-size-24 me-1 text-muted " style="cursor: grab;"></i>
                      <div>
                        <h5 class="mb-0 interact">
                          {{element.name}}                      
                        </h5>
                        <div class=" d-flex align-items-baseline" v-if="element.winner && element.winner.winner && element.winner.winner!=''">
                          <i class="bx bxs-trophy text-primary me-1"></i><p class="small mb-0 interact">{{ element.variants.filter(v=> v._id == element.winner.winner).length > 0 ?  element.variants.filter(v=> v._id == element.winner.winner)[0].name  : element.variants.length == 1 ? `${element.variants.length} ${$t('personalizations.variant')}` : `${element.variants.length} ${$t('personalizations.variants')}` }}</p> 
                        </div> 
                        <p class="small mb-0 interact" v-else>{{ element.variants.length == 1 ? `${element.variants.length} ${$t('personalizations.variant')}` : `${element.variants.length} ${$t('personalizations.variants')}`}}</p> 
                      </div>
                    </div>
                  </div>
									<div class="col-sm-4 text-end d-flex justify-content-end align-items-baseline" style="margin: auto;">
                    
                      <span class="badge font-size-12 me-5"
                                :class="{
                                'bg-success': `${element.status}` == 1,
                                'bg-warning': `${element.status}` == 0}">
                                  {{ element.status == 1 ? $t('personalizations.variant_status_active') : $t('personalizations.variant_status_incative') }}
                              </span>
                      
                    <div class="d-inline-flex align-items-baseline">
                      <i class="fas fa-flask text-primary me-2 interact" v-on:click="onABTestingReportClicked(element)" v-if="personalization._metrics?.filter(e=> e.target == element._id).length >0 && personalization._metrics?.filter(e=> e.target == element._id)[0].report && personalization._metrics?.filter(e=> e.target == element._id)[0].report.result"/>
                      <i class="fas fa-play text-primary me-2 interact" v-on:click="onChangeStatusClicked(index)" v-if="element._id && element.status == 0"/>
                      <i class="fas fa-pause text-primary me-2 interact" v-on:click="onChangeStatusClicked(index)" v-if="element._id && element.status == 1"/>
                      
                      <b-dropdown
                        class="card-drop"
                        variant="white"
                        right
                        toggle-class="p-0"
                        menu-class="dropdown-menu-end">
                        <template v-slot:button-content>
                        <i class="mdi mdi-dots-horizontal font-size-18"></i>
                        </template>

                        <b-dropdown-item @click="onCloneExperienceClicked(element)">
                          <i class="fas fa-clone text-info me-1 interact"></i>
                          {{$t('common.duplicate')}}
                        </b-dropdown-item>
                        <b-dropdown-item @click="onEditExperienceClicked(element)">
                          <i class="fas fa-edit text-success me-2 interact"></i>
                          {{$t('common.edit')}}
                        </b-dropdown-item>
                        <b-dropdown-item @click="onRemoveExperienceClicked(index)">
                          <i class="fas fa-trash-alt text-danger me-2 interact"></i>
                          {{$t('common.delete')}}
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
									
								</div>
							</div>
						</Draggable>
					</div>
				</div>
      </div>
      <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onRemoveExperienceConfirmClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
          <p>{{ $t('personalizations.remove_experience') }}</p>
      </b-modal>
			<b-modal
					v-model="showExperienceModal"
					id="modal-xl"
					size="xl"
					title-class="font-18"
          @ok.prevent="onNextOrConfirmExperienceClicked"  :okTitle="tabIndex == 0 ? $t('common.next') : personalization._id ? $t('personalizations.save_experience') : $t('common.confirm')"  :cancelTitle="$t('common.cancel')">
          <template #modal-title>
              <input class="form-control" v-model="experience.name" style="font-weight: bold;"/>
          </template>
				<Experience :experience="experience" :tabIndex="tabIndex" @indexChanged="onTabIndexChanged" :channel="channel" :personalizationId="personalization._id" :subtype="personalization.type || personalization.channel" :measures="personalization._metrics?.filter(e=> e.target == experience._id).length >0 ? personalization._metrics?.filter(e=> e.target == experience._id)[0] : null" :pageType="auxPageType?.id"></Experience>
			</b-modal>
      <b-modal
					v-model="showABTestingReportModal"
					id="modal-xl"
					size="xl"
					title-class="font-18"
          title="A/B Tests"
          hide-footer>
          <abTestingReports :experience="experience" :metrics="personalization._metrics?.filter(e=> e.target == experience._id).length >0 ? personalization._metrics?.filter(e=> e.target == experience._id)[0] : null"/>   
        </b-modal>
      
      <!-- end col -->
      <div class="row mt-4">
				<div class="col-sm-6">
				</div>
				<!-- end col -->
				<div class="col-sm-6">
					<div class="text-end">
						<b-button variant="light" class="w-md mb-3" @click="onCancelClicked">{{$t("common.cancel")}}</b-button>
						<b-button variant="primary" class="w-md ms-3 mb-3" @click="onConfirmClicked">{{$t("common.confirm")}}</b-button>
					</div>
				</div>
				<!-- end col -->
		</div>
    <!-- end row -->
    </div>
    
  </Layout>
</template>
<style>
.interact {
	cursor: pointer;
}
</style>