import { render, staticRenderFns } from "./customWebBuilder.vue?vue&type=template&id=770a73e5"
import script from "./customWebBuilder.vue?vue&type=script&lang=js"
export * from "./customWebBuilder.vue?vue&type=script&lang=js"
import style0 from "./customWebBuilder.vue?vue&type=style&index=0&id=770a73e5&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports