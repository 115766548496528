import CodeMirror from 'codemirror';

// Get the existing JavaScript mode
const jsMode = CodeMirror.getMode({}, 'javascript');

// Override the token function of JavaScript mode
function token(stream, state) {
  if (stream.match('{{', false)) {
    // Consume the opening {{
    stream.eatWhile('{');
    state.inTag = true;
    return 'tag';
  }

  if (state.inTag) {
    // If inside a tag, consume the stream until the closing }}
    if (stream.skipTo('}}')) {
      // Consume the closing }}
      stream.eatWhile('}');
      state.inTag = false;
    } else {
      stream.skipToEnd();
    }
    return 'tag';
  }

  return jsMode.token(stream, state.jsState);
}

// Define the new 'custom-javascript' mode
// eslint-disable-next-line no-unused-vars
CodeMirror.defineMode('custom-javascript', function(config) {
  return {
    token: token,
    startState: function() {
      return {
        inTag: false,
        jsState: CodeMirror.startState(jsMode),
      };
    },
    copyState: function(state) {
      return {
        inTag: state.inTag,
        jsState: CodeMirror.copyState(jsMode, state.jsState),
      };
    },
  };
});

// Set the new 'custom-javascript' mode as the default mode for JavaScript files
CodeMirror.defineMIME('text/javascript', 'custom-javascript');
CodeMirror.defineMIME('application/javascript', 'custom-javascript');
