<!-- eslint-disable no-useless-escape -->
<script>

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/addon/display/autorefresh.js';
import debounce from 'lodash.debounce';
import './custom-js-mode.js';
import CodeMirror from 'codemirror';
import Variables from "@/components/personalizations/variables";

export default {
  data() {
		return {
      jsonCode: '',
      jsonCodeMirror: null,
      showVariables:false,
      variables:[],
      indexTab:0,
      selectedText:'',
      buttonPosition: { top: 0, left: 0 },
      reservedVars:[
        "gs_variantId",
        "gs_experienceId",
        "gs_personalizationId",
        "#recommendations items",
        "/recommendations",
        "gs_recoStrategy",
        "gs_recoCount",
        "gs_recoStrategyAffinity",
        "gs_recommendations",
        "gs_recoStrategyCustom"
      ],
      selectedItem:{},
      groups: []
    }
  },
  components:{Variables},
  name: 'CustomJsonBuilder',
  props:{
    html: String,
    items: Array,
    affinity_Types: Array,
    categories: Array,
    pageType: String
  },
  computed:{
    showConvertButton : function() {
      if(this.selectedText && this.selectedText!='' && this.selectedText.trim().length > 2 && this.reservedVars.filter(v=>v.toUpperCase() == this.selectedText.trim().toUpperCase()).length == 0){
        return true;
      }
      
      return false
    }
  },
 mounted(){
  this.initializeCodeMirror();

  const vm = this;
  document.body.addEventListener('click', function (evt) {

    if (evt.target.className.includes('cm-variables')) {
        
      const name =  evt.target.textContent.trim();
      if(vm.reservedVars.filter(r=> r.toUpperCase() == name.toUpperCase()).length ==0){
        vm.indexTab = 0;
        if(vm.variables.filter(v=>v.name.toUpperCase() == name.toUpperCase()).length==0){
          let variable = {
            name,
            options:[],
            type:null,
          }
          vm.$refs.variablesComponent.onNewVariable(variable)
        }else{
          vm.$refs.variablesComponent.onVariableSettingClicked(vm.variables.filter(v=>v.name == name)[0])
        }
      }
    }
  }, false);
 },
 methods:{

  getCaseInsensitiveIndex(text, searchText) {
    const searchRegex = new RegExp(searchText, 'i');
    const match = text.match(searchRegex);
    return match ? match.index : -1;
  },
  shouldAddRecommendationsVariables(){

    const go_recoIndex = this.getCaseInsensitiveIndex(this.jsonCode, '{{gs_recommendations}}');
    
    if(go_recoIndex!=-1 && this.variables.filter(v=>v.type.id == "gs_recoStrategy").length == 0 &&  this.variables.filter(v=>v.type.id == "gs_recoCount").length == 0) {
      this.variables.push(
        {
          name: this.$t('personalizations.experience_variable_strategy'),
          type: {id:'gs_recoStrategy'},
          placeholder: this.$t('personalizations.experience_variable_strategy_placeholder'),
          tooltip: this.$t('personalizations.experience_variable_strategy_tooltip'),
        },
        {
          name: this.$t('personalizations.experience_variable_strategy_count'),
          type: {id:'gs_recoCount'},
          tooltip: this.$t('personalizations.experience_variable_strategy_count_tooltip'),
          placeholder: this.$t('personalizations.experience_variable_strategy_count_placeholder'),
        }
      );
      this.$refs.variablesComponent.setVariables(this.variables);
    }else if(go_recoIndex == -1 ){
      this.variables = this.variables.filter(v=> v.type.id != 'gs_recoStrategy' && v.type.id != 'gs_recoCount' );
      this.$refs.variablesComponent.setVariables(this.variables);
    }
  },
  initializeCodeMirror() {
        
      this.jsonCodeMirror = CodeMirror.fromTextArea(this.$refs.jsonTextArea, {
        mode: 'text/javascript',
        theme: 'material',
        lineNumbers: true,
        lineWrapping: true,
        value: this.jsonCode,
        viewportMargin: Infinity,
        autorefresh: true,
        tabSize: 2, 
        indentUnit: 2,
      });
      this.jsonCodeMirror.refresh();

      this.jsonCodeMirror.on('change', (cm) => this.updatePreview('json',cm));

      this.jsonCodeMirror.on('keydown', (cm, event)=> this.onEditorKeydown(cm,event));

      this.jsonCodeMirror.on('cursorActivity', this.debouncedHandleCursorActivityJson);
    },
    
    debouncedHandleCursorActivityJson: debounce(function() {
      this.handleCursorActivity('json');
    }, 300),

    handleCursorActivity(type) {

      if(!type)
        return;
      let editorPos;
      let cursorPos;
      if(type == 'json'){
        this.selectedText = this.jsonCodeMirror.getSelection();
        editorPos = this.jsonCodeMirror.getWrapperElement().getBoundingClientRect();
        cursorPos = this.jsonCodeMirror.cursorCoords(true, 'page');
      }
      
      if(this.reservedVars.filter(r=> r == this.selectedText).length > 0 || this.variables.filter(v=> v.name == this.selectedText).length > 0){
        this.selectedText = null;
        return;
      }

      const buttonTop = cursorPos.top - editorPos.top - 10; 
      const buttonLeft = cursorPos.left - editorPos.left - 10; 

      this.buttonPosition = {
        top: `${buttonTop}`,
        left: `${buttonLeft}`,
      };
    },
    onEditorKeydown(cm, event){
      var cursor = cm.getCursor();
      var lineContent = cm.getLine(cursor.line);
      var currentChar = lineContent.charAt(cursor.ch - 1);

      if (event.key === '{' && currentChar === '{') {
        cm.replaceRange('{}}', cursor, cursor);
        cm.setCursor(cursor.line, cursor.ch + 1);

        event.preventDefault(); 
      }
    },
    
    previewRecommendations(){
    
      if(this.variables.filter(v=> v.type.id == 'gs_recoStrategy').length > 0 && this.variables.filter(v=> v.type.id == 'gs_recoCount')[0].value){
        
        const recoCount = this.variables.filter(v => v.type.id === 'gs_recoCount')[0].value;
        const itemsToPreview = [];
        for (let i = 0; i < this.items.length  && i < recoCount ; i++) {
          itemsToPreview.push(this.items[i].data);
        }
        
        const replacedText = this.jsonCode.replace('{{gs_recommendations}}', JSON.stringify(itemsToPreview,null,4));
        
        return replacedText;
      }

      return this.jsonCode;
    },

    parsePreview(text){
      for (let i = 0; i < this.variables.length; i++) {
        let { name, value,type } = this.variables[i];

        if(type.id == "product_property_catalog"){
          if(this.variables[i].value?.column_name == 'product_category'){
            value = this.categories || [];
          }else if(this.variables[i].value){
            value = this.variables[i].value.values || [];
          }else {
            value = [];
          }
        }

        const variableRegex = new RegExp('\\{{' + name.trim() + '}}', 'g');
        
        text = text.replace(variableRegex, value);
      }
      
      return text.replace(/\{{.*?}}/g, ' ');
    },
    markText(cm) {
      cm.operation(() => {
        cm.getAllMarks().forEach((mark) => mark.clear());

        const content = cm.getValue();
        const pattern = /\{{([^}]*)}}/g;
        let match;
      
        while ((match = pattern.exec(content)) !== null) {
          const startPos = match.index + 2;
          const endPos = pattern.lastIndex - 2;
          
          cm.markText(
            cm.posFromIndex(startPos),
            cm.posFromIndex(endPos),
            { className: 'cm-variables' }
          );
        }
      });
    },
    updatePreview(type,cm) {
      
      this.jsonCode = this.jsonCodeMirror.getValue();
      
      if(cm)
        this.markText(cm);
      
      this.shouldAddRecommendationsVariables();

      const previewFrame = this.$refs.previewFrame;

      //const doc = previewFrame.contentDocument || previewFrame.contentWindow.document;

      const jsonBlob = new Blob([this.parsePreview(this.previewRecommendations())], { type: 'application/json' });
      const jsonUrl = URL.createObjectURL(jsonBlob);
      previewFrame.src = jsonUrl
      
    },
    
    getTemplatesValues(){
      return {
        json: this.jsonCode,
        type: 'json',
        variables: this.$refs.variablesComponent.getVariables(),
        groups: this.$refs.variablesComponent.getGroups()
      }
    },
    setTemplateValue(values){
      this.jsonCode = values.json || '';
      this.jsonCodeMirror.setValue(this.jsonCode);
      
      
      this.$refs.variablesComponent.setVariables(values.variables || [])
      this.$refs.variablesComponent.setGroups(values.groups || [])
      this.updateVariables(values.variables || []);
      this.updateGroups(values.groups || []);
    },
    updateVariables(variables){
      this.variables = variables;
      this.updatePreview();
    },
    updateGroups(groups){
      this.groups = groups;
    },
    onTabClicked(type){
      this.selectedText = '';
      switch(type){
        case 'json':
          setTimeout(()=>{
            this.jsonCodeMirror.focus();
            this.jsonCodeMirror.refresh();
          },100)
          break;
      }
    },
    onConvertToVariableClicked(){
      this.indexTab = 0;
      let variable = {
          name: this.selectedText,
          options:[]
        }
        this.$refs.variablesComponent.onNewVariable(variable)
    }
  }
}
</script>

<template>
  
  <div class="row">
    <div class="col-6">
      <div class="viewport-controls">
      </div>
      <iframe ref="previewFrame" class="preview-iframe" id="myIframe"></iframe>
    </div>
    <div class="col-6">
      <b-tabs v-model="indexTab" justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
        <b-tab active>
          <template v-slot:title>
            <span class="d-inline-block d-sm-none">
              Variables
            </span>
            <span class="d-none d-sm-inline-block"> <i class="mdi mdi-code-json"></i> VARS</span>
          </template>
          <div class="code-editor">
            <Variables ref="variablesComponent" @updateVariables="updateVariables" @updateGroups="updateGroups" :affinity_Types="affinity_Types" :pageType="pageType"/>
          </div>
        </b-tab>
        <b-tab v-on:click="onTabClicked('json')">
          <template v-slot:title>
            <span class="d-inline-block d-sm-none">
              JSON
            </span>
            <span class="d-none d-sm-inline-block"> <i class="mdi mdi-code-braces"></i> JSON</span>
          </template>
          <div class="code-editor">
            <textarea ref="jsonTextArea"></textarea>
          </div>
          <div class="button-container" v-if="showConvertButton" :style="{ top: buttonPosition.top + 'px', left: buttonPosition.left + 'px' }">
              <button class="btn btn-link btn-md" @click="onConvertToVariableClicked">{{$t('personalizations.experience_variables_convert')}}</button>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <b-modal
    title="Variable"
    v-model="showVariables"
    hide-footer>
    <div>
       <span v-for="v in variables" :key="v.id">
          {{v}}
       </span> 
    </div>
  </b-modal>
  </div>
</template>

<style>
.code-editor {
  width: 100%;
  height: 600px;
  margin-bottom: 10px;
}

.preview {
  width: 100%;
  height: 600px;
}

.preview-iframe{
  width: 100%;
  background-color: #f8f8fb;
  height: 600px;
  display: block;
  margin: auto;
}

.CodeMirror{
  height: 600px !important;
}

.interact {
  cursor: pointer;
}

.cm-variables{
  background-color: rgba(241,180,76,.25)!important;
  cursor: pointer;
}

.cm-reco{
  background-color: rgba(195, 191, 206, 0.359)!important;
}

.button-container {
  position: absolute;
  background-color: white;
  padding: 5px;
  border: 1px solid black;
}

#myIframe {
  border: none;
  width: 100%;
  height: 100%;
}

#myIframeContent {
  white-space: pre-wrap;
  font-family: 'Courier New', monospace;
  padding: 10px;
}

#myIframeContent .json-key {
  color: #333;
  font-weight: bold;
}

#myIframeContent .json-value {
  color: #e63946;
}
</style>

