<script>
import ExpressionBuilder from "@/components/widgets/queryBuilder";
import Variants from "@/components/personalizations/variants";
import Multiselect from "vue-multiselect";
import EmptyList from '@/components/widgets/empty_list'
import targetFields from '@/components/personalizations/targetFields'

import {
	audienceMethods,
	propertyMethods,
	projectMethods,
	interactionMethods
} from "@/state/helpers";

/**
 * New Campaign  component
 */

export default {
	components: {
		ExpressionBuilder,
		Variants,
		Multiselect,
		EmptyList,
		targetFields
	},
	data() {
		return {
			submitted:false,
			properties_products:[],
			custom_interactions: [],
			affinity_Types: [],
			audiences:[],
			optionValue: null,
			index:0,
			options: [
				this.$t("personalizations.experience_targeting_options_all"),
				this.$t("personalizations.experience_targeting_options_filter"),
			],
			empty_config:{
        "title" : this.$t("personalizations.experience_targeting_empty"),
        "subtitle" : this.$t("personalizations.experience_targeting_empty_subtitle"),
        "buttonText" : this.$t("audiences.audience_conditions_add_condition"),
        //"secondButtonText" :this.$t("audiences.audience_conditions_add_group"),
        icon: "fa-cubes"
      },
			categories:[],
			metrics: [
				{
					id: "purchase",
					label : this.$t('personalizations.experience_variants_winner_options_purchase')
				},
				{
					id: "ctr",
					label : this.$t('personalizations.experience_variants_winner_options_ctr')
				},
				{
					id: "revenue",
					label : this.$t('personalizations.experience_variants_winner_options_revenue')
				},
			],
			minDurationOptions: [
				{
					id: '1_week',
					label: this.$t('personalizations.experience_variants_min_duration_options_one_week')
				},
				{
					id: '2_weeks',
					label: this.$t('personalizations.experience_variants_min_duration_options_two_weeks')
				},
				{
					id: '1_month',
					label: this.$t('personalizations.experience_variants_min_duration_options_one_month')
				},
				{
					id: 'no_time',
					label: this.$t('personalizations.experience_variants_min_duration_options_without_min_time')
				},
			],
			metric : {
					id: "purchase",
					label : this.$t('personalizations.experience_variants_winner_options_purchase')
			},
			showABSettingsModal:false,
			minDurationOption : {
				id: '2_weeks',
				label: this.$t('personalizations.experience_variants_min_duration_options_two_weeks')
			},
			winnerSignificance:95,
			metricText: this.$t('personalizations.experience_variants_winner_purchase'),
			winnerEndTime: null,
			showTargetFields : false,
			contactLists: []
		};
	},
	methods: {
		...propertyMethods,
		...audienceMethods,
		...projectMethods,
		...interactionMethods,

		loadContactLists(){
      
      const params = {
				q : `where[project]=${localStorage.getItem('current_project')}&limit=100&where[status]=completed`
			}
			this.getContactLists(params).then((lists)=>{
        if(lists&& lists.data){
          this.contactLists= lists.data;
        }
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("contact_lists.get_contact_lists_error"),title:  this.$t("contact_lists.title") });
      }).finally(()=>{
        this.isLoading = false;
        
      });
    
    },

		addFixedProperties(){
      this.properties_products.push({
        type : 'product_price',
        name: this.$t("audiences.audience_conditions_fields_interaction_products_with_price"),
        column_name: 'product_price'
      });
			this.properties_products.push({
        type : 'product_category',
        name: this.$t("audiences.audience_conditions_fields_interaction_products_with_category"),
        column_name: 'product_category'
      });
      
    },
		loadAudiences(){
      let loader = this.$loading.show();
      const params = {
        q : `where[project]=${localStorage.getItem("current_project")}&limit=100`
      }
      this.getAudiences(params).then((audiences)=>{
        this.audiences = audiences.data || [];
      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
    },

		loadCategories(){
      const params = {
				q : `project=${localStorage.getItem("current_project")}`
			}
			
			this.getItemsCategories(params).then((categories)=>{
				this.categories = categories;
			}).catch(()=>{

			}).finally(()=>{
				
			})
		},

		loadProperties(){
      let loader = this.$loading.show();
      const params = {
        q : `project=${localStorage.getItem("current_project")}`
      }
      this.getProperties(params).then((properties)=>{
        this.properties_products = properties.filter(p=> p.entity == 'product');
        this.affinity_Types = this.properties_products.filter(p=> p.type== 'list');

				this.affinity_Types.push({
          type : 'product_category',
          name: this.$t("audiences.audience_conditions_fields_interaction_products_with_category"),
          column_name: 'product_category'
        });

        this.addFixedProperties();
        
      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
    },

		loadCustomInteractions(){
			const params = {
        q : `where[project]=${localStorage.getItem('current_project')}`
      }
      this.getInteractions(params).then((res)=>{
        if(res.data){
          this.custom_interactions = res.data;  
        }
      })
		},
		onNextClicked(){
			if(this.index == 0){
				this.index = 1;
				return;
			}
		},
		onTabSelected(index){
			this.$emit('indexChanged',index);
		},
		onTargetOptionChanged(option){
			if(option == this.options[0]){
				this.experience.rules.children = [];
			}
		},
		onCreateConditionClicked(){
			/*this.experience.rules.children.push({
            field: null,
            operator: null,
            term: null
          });*/

					this.showTargetFields = true;
      },
      onCreateGroupClicked(){
        this.experience.rules.children.push({
            group: true,
            type: "and",
            children: [
              {
                field: null,
                operator: null,
                term: null
              }
            ]
        })
      },
			onABSettingsClicked(){
				this.showABSettingsModal = true;
			},
			onConfirmSettingsClicked(){
				if(!this.winnerSignificance || this.winnerSignificance < 75 && this.winnerSignificance > 100 || this.winningEndDateState!=null)
					return;

				this.experience.settings = {
					winnerSignificance : this.winnerSignificance,
					measure : this.metric.id,
					minDuration: this.minDurationOption.id,
					winnerEndTime: this.winnerEndTime
				}

				this.showABSettingsModal =false;
			},
			formatDate(date) {
				var d = new Date(date),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();

				if (month.length < 2) 
					month = '0' + month;
				if (day.length < 2) 
					day = '0' + day;
				
				return [day, month, year].join('/');
			},
			variantName(winner){
				const variant = this.experience.variants.filter(v=>v._id == winner).length > 0 ?this.experience.variants.filter(v=>v._id == winner)[0] : null;

				return variant ? variant.name : 'Winner'
			},
			winnerSubtitle(winner){
				
				if(!winner || !winner.winner && !this.experience.settings || !this.measures){
					return '';	
				}
				const variant = this.experience.variants.filter(v=>v._id == winner.winner).length > 0 ?this.experience.variants.filter(v=>v._id == winner.winner)[0] : null;
				if(!variant) return '';
				let text = '';
				const winnerData = this.measures.data.filter(d=> d.variantId == variant._id)[0]; 
				
				switch (this.experience.settings.measure) {
					case 'purchase': 
						text= this.$t('personalizations.experience_variants_subtitle_winner_purchases');
						text = text.replace('##', winnerData?.conversions).replace('###', winnerData?.samples);
						break;
					case 'ctr':
						text= this.$t('personalizations.experience_variants_subtitle_winner_ctr');
						text= text.replace('##', winnerData?.ctr).replace('###', winnerData?.samples);
						break;
					case 'revenue':
						text= this.$t('personalizations.experience_variants_subtitle_winner_revenue')
						text= text.replace('##', winnerData?.revenue).replace('###', winnerData?.samples);
						break;
					case 'cr':
						text= this.$t('personalizations.experience_variants_subtitle_winner_conversion_rate')
						text= text.replace('##', winnerData?.conversion_rate).replace('###', winnerData?.samples);
						break;
				}

				return text;
			},
			winnerSubtitleDates(winner){
        let text = text= this.$t('personalizations.experience_variants_subtitle_winner_dates');
        text = text.replace('##', this.formatDate(this.experience?.firstImpressionDate)).replace('###', this.formatDate(winner.date));

        return text;
      },
			onTargetFieldSelected(id){

				this.experience.rules.children.push({
            field: id,
            operator: null,
            term: null,
						times:{},
						new: true
          });
					this.showTargetFields = false;
      },
	},
	computed: {
		winningState() {
			return this.winnerSignificance && this.winnerSignificance > 74 && this.winnerSignificance < 101  ? null : false
		},
		winningEndDateState() {
			return this.winnerEndTime == null || new Date(this.winnerEndTime) >= new Date()  ? null : false
		}
	},
	mounted() {
		this.loadProperties();
		this.loadAudiences();
		this.loadCategories();
		this.loadCustomInteractions();
		this.loadContactLists();
	},
	props: {
		experience : Object,
		tabIndex: Number,
		channel: String,
		subtype: String,
		personalizationId: String,
		measures: Object,
		pageType: String
	},
	emits: ['indexChanged'],
	watch: {
		metric: {
			immediate: true,
      handler(newVal){
        if(newVal){
					if(newVal.id == 'purchase')
						this.metricText = this.$t('personalizations.experience_variants_winner_purchase')
					else if(newVal.id == 'ctr')
						this.metricText = this.$t('personalizations.experience_variants_winner_ctr')
					else if(newVal.id == 'revenue')
						this.metricText = this.$t('personalizations.experience_variants_winner_revenue')
        }
      }
		},
		tabIndex:{
      immediate: true,
      handler(newVal){
        if(newVal){
					this.index = newVal;
        }
      }
    },
		experience:{
			immediate: true,
      handler(newVal){
				if(newVal && newVal.rules && newVal.rules.children.length == 0){
					this.optionValue = this.options[0];
        }else{
					this.optionValue = this.options[1];
				}

				if(newVal.settings){
					this.winnerSignificance = newVal.settings.winnerSignificance ? newVal.settings.winnerSignificance : this.winnerSignificance;
					this.metric = newVal.settings.measure && this.metrics.filter(m=>m.id == newVal.settings.measure).length > 0 ? this.metrics.filter(m=>m.id == newVal.settings.measure)[0] : this.metric;
					this.minDurationOption = newVal.settings.minDuration && this.minDurationOptions.filter(m=>m.id == newVal.settings.minDuration).length > 0 ? this.minDurationOptions.filter(m=>m.id == newVal.settings.minDuration)[0] : this.minDurationOption;
					this.winnerEndTime = newVal.settings.winnerEndTime;
				}else{
					if(this.experience){
						this.experience.settings = {
							winnerSignificance : this.winnerSignificance,
							measure : this.metric.id,
							minDuration: this.minDurationOption.id,
							winnerEndTime: null
						}
					}
				}

				if(newVal.winner?.winner){
					newVal.variants.sort((a, b) => {
					if (a._id === newVal.winner?.winner) {
						return -1; 
					}
					if (b._id === newVal.winner?.winner) {
						return 1; 
					}
					return 0; 
				});
				}
      }
		},
		measures: {
			immediate:true,
			handler(newVal){
				if(newVal && newVal.latestData){
					
					newVal.latestData.forEach((d)=>{
						const variant = this.experience.variants.filter(v=> v._id == d.variantId);
						if(variant && variant.length > 0){
							variant[0].data = d;
						}
					});
					if(newVal.report && newVal.report.result){
						this.experience.variants.forEach((v)=>{
							v.report = newVal.report.result[v._id];
						})
						
					}
					
				}			
			}
		}
  }
};
</script>

<template>
	<div class="row">
		<b-tabs v-model="index" justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
			<b-tab v-on:click="onTabSelected(0)">
				<template v-slot:title>
					<span class="d-inline-block d-sm-none">
						{{$t('personalizations.experience_targeting')}}
					</span>
					<span class="d-none d-sm-inline-block"> <i class="bx bx-target-lock"></i> {{$t('personalizations.experience_targeting')}}</span>
				</template>
				<h5>{{$t('personalizations.experience_targeting_subtitle')}}</h5>
				<multiselect
						:options="options" 
						v-model="optionValue"
						selectLabel=""
						deselectLabel=""
						:multiple="false"
						:allowEmpty="false"
						class="form control form-control-sm w-50"
						@input="onTargetOptionChanged"
						/>
				
						<EmptyList :config="empty_config" v-if="optionValue == options[1] && experience.rules.children.length == 0" @onButtonClicked="onCreateConditionClicked" class="mt-4"/>
						<ExpressionBuilder :query="experience.rules" :conditionType="'targetType'" :properties_products="properties_products" :affinity_Types="affinity_Types" :audiences="audiences" v-if="optionValue == options[1] && experience.rules.children.length > 0" :categories="categories" :custom_interactions="custom_interactions" :contactLists="contactLists" />
			</b-tab>
			<b-tab v-on:click="onTabSelected(1)">
				<template v-slot:title>
					<span class="d-inline-block d-sm-none">
						<label>{{$t('personalizations.experience_variants')}}</label>
					</span>
					<span  class="d-none d-sm-inline-block"> <i class="bx bxs-flask"></i> {{$t('personalizations.experience_variants')}}</span>
				</template>
				<h5 class="mb-1" v-if="experience.variants.length > 0">{{ experience.winner && experience.winner.winner != '' ? $t('personalizations.experience_variants_subtitle_winner') : $t('personalizations.experience_variants_subtitle')}}</h5>
				<div class="row" v-if="experience.variants.length > 0">
					<div class="col d-inline-flex align-items-baseline" v-if="!experience.winner|| experience.winner?.winner == ''">
						<i class="bx bx-trophy font-size-16 me-1 text-primary"/>  <p class="mb-0 me-2">{{ metricText }}</p> <i class="bx bx-cog font-size-16 interact" v-on:click="onABSettingsClicked"/>
					</div>
					<div class="col d-inline-flex align-items-baseline bg-primary bg-gradient p-2" v-if="experience.winner && experience.winner.winner != ''">
						<i class="bx bx-trophy font-size-24 me-2 text-white align-self-center"/>
						<div>
							<p class="mb-0 me-2 text-white"> <span class="fw-bold text-white">{{ variantName(experience.winner?.winner) }}</span> {{winnerSubtitle(experience.winner)}}</p>
							<p class="mb-0 font-size-10 text-white">{{ winnerSubtitleDates(experience.winner) }}</p>	
						</div>
					</div>
				</div>
				<Variants :variants = "experience.variants" :channel="channel" :affinity_Types="affinity_Types" :personalizationId="personalizationId" :experienceId="experience._id" :type="subtype" :categories="categories" :winner="experience.winner" :pageType="pageType"></Variants>
			</b-tab>
		</b-tabs>
		<b-modal v-model="showABSettingsModal" :title="$t('personalizations.experience_variants_settings')" :okTitle="$t('common.confirm')" @ok.prevent="onConfirmSettingsClicked">
			<div class="row mb-3">
				<div class="col">
					<label class="mb-0">{{$t('personalizations.experience_variants_settings_winner')}}</label>
					<multiselect
						:options="metrics" 
						v-model="metric"
						selectLabel=""
						deselectLabel=""
						:multiple="false"
						:allowEmpty="false"
						class="form control"
						track-by="id" 
						label="label"
						placeholder=""
					/>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col">
					<label class="mb-0">{{$t('personalizations.experience_variants_settings_min_duration')}}</label>
					<multiselect
						:options="minDurationOptions" 
						v-model="minDurationOption"
						track-by="id" 
						label="label"
						selectLabel=""
						deselectLabel=""
						:multiple="false"
						:allowEmpty="false"
						class="form control"
						placeholder=""
						/>
				</div>
			</div>
			<div class="row">
				<div class="col-6">
					<label class="mb-0">{{$t('personalizations.experience_variants_settings_percentage_winner')}}</label>
					<b-form-input type="number" v-model="winnerSignificance" :state="winningState"></b-form-input>
				</div>
				<div class="col-6">
					<label class="mb-0">{{$t('personalizations.experience_variants_settings_end_time')}}</label>
					<b-form-input type="date" v-model="winnerEndTime" :state="winningEndDateState"></b-form-input>
				</div>
			</div>
		</b-modal>
		<b-modal v-model="showTargetFields" hide-footer :title="$t('audiences.audience_conditions')">
			<targetFields @onValueClicked="onTargetFieldSelected" :custom_interactions="custom_interactions" />
		</b-modal>
	</div>
	
</template>

<style scoped>

.interact{
	cursor: pointer;
}
</style>